<template>
    <div>
        <div class="input_label">{{ $t('documents') }}</div>
        <v-file-input
            v-show="false"
            v-model="localDocuments"
            ref="fileInputRef"
            id="file-input"
            multiple
            @change="handleFileInputChange"
            :disabled="loading"
        >
        </v-file-input>

        <div>
            <div
                class="drop-zone"
                @drop="dropFile"
                @dragover="handleDragover"
                @dragenter="handleDragover"
                @click="triggerFileInputClick"
            >
                Перетащите документы сюда или
                <span class="upload-button" @click="triggerFileInputClick" >
                    загрузите файл
                </span>.

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'FileUpload',
    props: {
        documents: Array,
        loading: Boolean,
    },
    data() {
        return {
            localDocuments: [...this.documents],
            isDraggingOver: false,
        };
    },
    watch: {
        documents(newVal) {
            this.localDocuments = [...newVal];
        },
        isDraggingOver(val) {
            this.$el.querySelector('.drop-zone').classList.toggle('drag-over', val);
        }
    },
    methods: {
        handleDragover(e) {
            e.stopPropagation();
            e.preventDefault();
            e.dataTransfer.dropEffect = 'copy';
            this.isDraggingOver = true;
        },
        dropFile(e) {
            e.stopPropagation();
            e.preventDefault();
            this.isDraggingOver = false;
            this.$emit('file-dropped', Array.from(e.dataTransfer.files));
        },
        triggerFileInputClick() {
            this.$nextTick(() => {
                const fileInput = this.$refs.fileInputRef ? this.$refs.fileInputRef.$el.querySelector('input[type="file"]') : null;
                if (fileInput) {
                    fileInput.click();
                }
            });
        },
        handleFileInputChange() {
            this.$emit('file-dropped', this.localDocuments);
        },
    },
};
</script>

<style lang="scss">
.drop-zone {
    border: solid 2px #dcdcdc;
    border-radius: 8px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: border-color 0.3s ease;
    cursor: pointer;
}

.drop-zone:hover {
    border-color: #007EC9;
}

.drop-zone.drag-over {
    border-color: #28a745;
}

.upload-button {
    margin-left: 5px;
    color: #007EC9;
    cursor: pointer;
}
</style>
