<script>
import debounce from "lodash/debounce";
import {buildListArrayFromTreeArray, buildTree} from "@/plugins/functions";
import TreeMenu from "@/components/Leentech/TreeMenu.vue";
import User from "@/components/User.vue";

export default {
    name: "UserSelectionPopup",
    components: {
        User,
        TreeMenu
    },

    props: {
        searchValue: {
            type: String,
            default: ''
        },

        focusItemIndex: {
            type: Number,
            default: null
        },

        value: {
            required: false,
        },

        multiple: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            items: [],
            loading: false,
            selected: [],
            innerValue: null,

            departments: null,
            roles: null,
            positions: null,
            filters: [],
            isPopupShown: false,
            loaded: false,
            lastFilter: null
        }
    },

    watch: {
        searchValue: debounce(function (value) {
            if (value) {
                this.fetchUsers({
                    name: {
                        contains: value
                    },
                    ...this.lastFilter
                }).then(items => {
                    this.items = items
                })
            } else {
                this.fetchUsers(this.lastFilter).then(items => {
                    this.items = items
                })
            }
        }, 500),

        value: {
            handler: function (value) {
                this.innerValue = value;
                this.fetchUsers(this.lastFilter).then(items => {
                    this.items = items
                });
            },
            deep: true
        },

        departments: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        roles: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        positions: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        focusItemIndex: function (value) {
            if (value !== null) {
                this.$nextTick(() => {
                    const item = this.$refs['users-list'].querySelectorAll('.user-select__item').item(value);

                    if(item) {
                        item.focus()
                    }
                });
            }
        }
    },

    methods: {
        showPopup: function () {
            if(this.loaded) {
                return;
            }

            this.fetchUsers({
                name: {
                    contains: this.searchValue
                }
            }).then((items) => {
                 this.items = items
            })

            this.fetchDepartments().then((items) => {
                this.departments = items;
            })

            this.fetchPositions().then((items) => {
                this.positions = items
            })

            this.fetchRoles().then(items => {
                this.roles = items;
            })

            this.loaded = true;
        },

        hidePopup: function () {
            this.isPopupShown = false;
        },

        updateValue: function (value) {
            this.innerValue = value
            this.$emit('input', this.innerValue)
        },

        clear() {
            this.updateValue([])
            this.items = [];
        },

        async fetchUsers(filter) {
            let fltr = {
                active: {
                    eq: '1'
                },
            };

            if(this.multiple) {
                if(this.innerValue && this.innerValue.length > 0) {
                    fltr.id = {
                        notin: this.innerValue.map(item => item.id)
                    }
                }
            } else {
                if(this.innerValue) {
                    fltr.id = {
                        notin: [this.innerValue.id]
                    }
                }
            }

            if(this.searchValue) {
                fltr.name = {
                    contains: this.searchValue
                }
            }

            fltr = {
                ...fltr,
                ...filter
            }

            let params = {
                perPage: 20,
                fltr: fltr
            };

            this.loading = true

            let res = await this.$http.get('admin/admin', {
                params: params
            })

            // on error
            if (res.status < 200 && res.status >= 300) {
                this.$toastr.error(this.$t('failed_to_get_administrator'))
                return [];
            }

            this.loading = false
            let user = res.body.data;

            this.$emit('users-fetch', user);

            return user;
        },

        input(value) {
            this.updateValue(value);
        },

        async fetchDepartments() {
            let self = this;
            let params = {};
            let res = await this.$http.get('admin/department', {
                params: params
            });

            let deps = res.body.data;

            deps.forEach((item) => {
                let repeatCount = (item.depth ?? 1) - 1;
                item.name = ('. '.repeat(repeatCount)) + item.name;
                item.click = function (itm) {
                    self.onDepartmentClick(itm)
                }
                return item;
            })

            deps = buildTree(deps, 0, 'id', 'parent_id', 'children');
            deps = buildListArrayFromTreeArray(deps);

            return deps;
        },

        async fetchRoles() {
            let self = this;
            let params = {};
            let res = await this.$http.get('admin/role', {
                params: params
            });

            return res.body.data.map(item => {
                return {
                    ...item,
                    name: item.display_name,
                    slug: item.name,
                    click: function (itm) {
                        self.onRoleClick(itm)
                    }
                }
            });
        },

        async fetchPositions() {
            let self = this;
            let params = {
                perPage: 0
            };
            let res = await this.$http.get('admin/employee_position', {
                params: params
            });

            return res.body.data.map(item => {
                return {
                    ...item,
                    click: function (itm) {
                        self.onPositionClick(itm)
                    }
                }
            });
        },

        onParentClick(item, path) {
            let tree = this.filters;

            while (path.length > 0) {
                let index = path.shift();

                if(path.length === 0){
                    tree.forEach((itm, i) => {
                        if(i === index) {
                            tree[index].active = 'active' in tree[index] ? !tree[index].active : true;
                        } else {
                            tree[i].active = false;
                        }
                    });

                    if ('click' in item) {
                        item.click(item);
                    }
                }

                tree = tree[index].children;
            }

            this.$set(this, 'filters', [...this.filters]);
        },

        onDepartmentClick(itm) {
            this.lastFilter = {
                department_id: {
                    eq: itm.id
                }
            };

            this.fetchUsers(this.lastFilter).then(items => {
                this.items = items;
            })
        },

        onRoleClick(itm) {
            this.lastFilter = {
                role_id: {
                    eq: itm.id
                }
            };

            this.fetchUsers(this.lastFilter).then(items => {
                this.items = items;
            })
        },

        onPositionClick(itm) {
            this.lastFilter = {
                employee_position_id: {
                    eq: itm.id
                }
            }
            this.fetchUsers(this.lastFilter).then(items => {
                this.items = items;
            })
        },

        buildFilters() {
            let self = this;
            return [
                {
                    name: this.$t('all'),
                    click: function () {
                        self.lastFilter = {};
                        self.fetchUsers(this.lastFilter).then(items => {
                            self.items = items
                        });
                    }
                },
                {
                    name: this.$t('roles'),
                    children: this.roles
                },
                {
                    name: this.$t('positions'),
                    children: this.positions
                },
                {
                    name: this.$t('departments.plural_form'),
                    children: this.departments
                },
            ]
        },

        deleteUser(id) {
            if (this.multiple) {
                let result = [];

                if (this.value.length > 0) {
                    result = this.value;
                }

                result = result.filter((item) => {
                    return item.id !== id;
                });

                this.updateValue(result);
            } else {
                this.updateValue(null)
            }
        },

        selectUser(item) {
            if (this.multiple) {
                let result = [];

                if (this.innerValue && this.innerValue.length > 0) {
                    result = this.innerValue;
                }

                if (!result.find((itm) => { return item.id === itm.id})) {
                    result.push(item);
                }

                this.updateValue(result);
            } else {
                this.updateValue(item);
                this.hidePopup()
            }
        }
    },

    mounted() {
        this.innerValue = this.value;
        this.showPopup();
    }
}
</script>

<template>
    <div class="user-select__popup">
        <div class="user-select__users" ref="users-list">
            <template v-if="!loading">
                <template v-if="items && items.length > 0">
                    <div v-for="(item, index) in items" :key="item.id" class="user-select__item" :class="{'active': focusItemIndex === index}" @click="selectUser(item)">
                        <User :item="item"></User>
                    </div>
                </template>
                <template v-else>
                    <div class="user-select__nothing">
                        <img src="@/assets/nothing-found.svg" alt="Nothing found" class="user-select__nothing-found">
                        <span>{{ $t('nothing_found') }}</span>
                    </div>
                </template>
            </template>
        </div>
        <div class="user-select__filters">
            <TreeMenu
                :items="filters"
                @item-clicked="onParentClick"
                @item-parent-clicked="onParentClick"
            ></TreeMenu>
        </div>
    </div>
</template>

<style lang="scss">
.user-select__popup {
    display: flex;
    z-index: 10;
    background-color: #fff;
    height: 300px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}

.user-select__users {
    overflow: auto;
    width: 60%;
    padding-top: $spacer * 1.5;
    padding-bottom: $spacer * 1.5;
}

.user-select__filters {
    overflow: auto;
    background-color: var(--body);
    width: 40%;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
            width: 100%;

            span {
                margin: 0;
                padding: 6px $grid-gutter / 2;
                display: block;
                cursor: pointer;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            &.visible {
                //& > span {
                //    background-color: rgba(0, 0, 0, 0.2);
                //}

                & > ul {
                    display: block;
                }
            }
        }

        ul {
            li {
                span {
                    padding: 6px $grid-gutter;
                }
            }

            display: none;


        }
    }
}

.user-select__nothing {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.user-select__item {
    padding: $spacer * 1.5 $spacer * 3;
    cursor: pointer;

    &:hover, &:focus, &.active {
        background-color: rgba(0,0,0,0.1);
    }
}
</style>
