<template>
    <li :class="{'parent': 'children' in item, 'visible': item.active }">
        <span @click="click(item, path)">
            {{ item.name }}
            <template v-if="item.children">
                <v-icon v-if="item.active">mdi-chevron-down</v-icon>
                <v-icon v-else>mdi-chevron-right</v-icon>
            </template>
        </span>
        <ul v-if="item.children && item.children.length">
            <template v-for="(child, i) in item.children">
            <tree-item
                :item="child"
                :path="[...path, i]"
                @item-parent-clicked="handleClick"
                @item-clicked="handleClick"
            ></tree-item>
            </template>
        </ul>
    </li>
</template>

<script>
export default {
    name: 'TreeItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        path: {
            type: Array,
            default: () => [],
        }
    },

    methods: {
        click(item, path) {
            this.handleClick(item, path)
        },

        handleClick(item, path) {
            this.$emit('item-clicked', item, path);
        },
    },
};
</script>
