import _ from 'lodash'
const trailingSlashRE = /\/?$/;


/**
 *
 * @param a
 * @param b
  @returns {false|boolean|*}
 */
export function isSameRouteWithQueryParams(a, b) {
    if (!a || !b) return false;
    if (a.path !== b.path) return false;
    if (!_.isEqual(a.query ?? {}, b.query ?? {})) return false;
    return true;
}

export function buildTree(elements, parentId = 0, idKeyName = 'id', parentIdKeyName = 'parent_id', childrenKeyName = 'children') {
    let branch = [];

    elements.forEach(element => {
        if (element[parentIdKeyName] === parentId) {
            let children = buildTree(elements, element[idKeyName], idKeyName, parentIdKeyName, childrenKeyName);
            if (children.length) {
                element[childrenKeyName] = children;
            }
            branch.push(element);

            elements = elements.filter(e => e[idKeyName] !== element[idKeyName]);
        }
    });

    return branch;
}

export function buildListArrayFromTreeArray(arTree, childrenKeyName = 'children') {
    let arResult = [];

    arTree.forEach(arItem => {
        let tmpItem = { ...arItem };
        delete tmpItem[childrenKeyName];

        arResult.push(tmpItem);
        if (arItem[childrenKeyName]) {
            arResult.push(...buildListArrayFromTreeArray(arItem[childrenKeyName]));
        }
    });

    return arResult;
}
