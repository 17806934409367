<template>
    <ul>
        <tree-item
            :item="item"
            :path="[index]"
            v-for="(item, index) in items"
            :key="[index].join('-')"
            @item-clicked="handleItemClick"
        ></tree-item>
    </ul>
</template>

<script>
import TreeItem from './TreeItem.vue';

export default {
    name: 'TreeMenu',
    components: {
        TreeItem,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },

    watch: {
        items: {
            handler: function (newVal, oldVal) {
                console.log('items changed');
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            },
            deep: true,
        },
    },

    methods: {
        handleItemClick(item, path) {
            this.$emit('item-clicked', item, path);
        },
    },
};
</script>
